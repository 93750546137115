/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import itIT from "antd/es/locale/it_IT";
import moment from "moment";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
moment.locale("it");

ReactDOM.render(
  <ConfigProvider locale={itIT}>
    <Router>
      <App />
    </Router>
  </ConfigProvider>,
  document.getElementById("root"),
);
