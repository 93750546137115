/** @format */

export enum Routes {
  home = "/",
  userDetail = "/dettagli/:idUser",
  userCreate = "/creazione",
  assignLoyalty = "/assegnazione/:key?",
  homeUser = "/user",
  userCreateUser = "/creazione/user",
  assignLoyaltyUser = "/assegnazione-user/:key?",
  viewButton = "/button/:urlBase",
}
