/** @format */

import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";

interface Props extends InputProps {}

export const UIInput = styled(Input)<Props>`
  border-radius: 10px;

  .ant-input-affix-wrapper {
    border-radius: 10px !important;
  }
  .ant-input-password {
    border-radius: 10px !important;
  }
  .ant-input-group-addon {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
  .ant-input {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`;
