/** @format */

export enum Colors {
  primary = "#F7F6F1",
  primaryBorder = "rgba(247, 246, 241,.25)",
  cream = "#F7F6F1",
  background = "#181818",
  disabled = "#adadad4d",
  gray = "#F7F6F1",
  dark = "#292929",
  green = "#2BAC76",
  blue = "#009edf",
  red = "#E01E5A",
  pink = "#F8D7DA",
  yellow = "#F5A623",
  darkGray = "#525252",
  darkBlue = "#00489f",
  darkBlueRGBA = "rgba(0, 121, 255, .25)",
  loadingSublabel = "#373737",
  subGroup = "#292929",
  grayLabel = "#7B7B78",
}
