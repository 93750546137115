/** @format */

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { API_PARAMS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { UIText } from "../../ui/Text";

export const ViewButton = (): JSX.Element => {
  //@ts-ignore
  const { urlBase } = useParams();
  const urlObj = atob(urlBase);
  const [url, setUrl] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    console.log(urlObj);
    if (!!JSON.parse(urlObj).error) {
      setIsError(true);
    } else {
      apiClient(API_PARAMS.BASE_URL_AES + "?json=" + btoa(urlObj)).then(res =>
        setUrl(res.data.url),
      );
    }
  }, []);

  return (
    <>
      {!!url && (
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <a href={url}>
              <img src="https://app.gowento.com/wallet-add-button.png?width=300" />
            </a>
          </Col>
        </Row>
      )}
      {!!isError && (
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={24} style={{ textAlign: "center" }}>
            <UIText style={{ fontSize: "16px" }}>
              Utente già in iscritto alla loyalty. Controlla la tua casella email per visualizzare
              il tuo pass.
            </UIText>
          </Col>
        </Row>
      )}
    </>
  );
};
