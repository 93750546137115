/** @format */

import { Col, Row, Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { API_ENDPOINTS, API_PARAMS } from "../../../constants/api";
import apiClient, { triggerClient } from "../../../utils/apiClient";
import { UIButton } from "../../ui/Button";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { utilitySaveToLocalStorage } from "../../../utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "../../../utils/cache/getFromLocalStorage";
import { UIDatePicker } from "../../ui/DatePicker";
import moment from "moment";
import qs from "qs";
import { Routes } from "../../../utils/Routes";
const { Option } = UISelect;

export const ViewAssignLoyalty = (): JSX.Element => {
  const history = useHistory();
  //@ts-ignore
  const { key } = useParams();
  const [programList, setProgramList] = useState<[]>([]);

  const countDownModal = (): void => {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Operazione Completata",
      content: `Presto riceverai un'email al tuo indirizzo di posta elettronica!`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
      history.push(Routes.home);
    }, secondsToGo * 1000);
  };

  //@ts-ignore
  const handleAssign = (e): void => {
    const userObj = {
      program_id: e.program_id,
      contact_id: e.key,
      birthday: moment(e.birthday).format("YYYY-MM-DD"),
    };
    //dispatch

    apiClient
      .post(API_ENDPOINTS.CREATE_LOYALTY, userObj, {
        headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
      })
      .then(res => {
        /* const user = {
          //@ts-ignore
          externalId: res.data.individual.custom_fields.find(el => el === "Chiave")
            ? //@ts-ignore
              res.data.individual.custom_fields.find(el => el === "Chiave").value
            : "",
          firstName: res.data.individual.firstname,
          lastName: res.data.individual.lastname,
          email: res.data.individual.email,
          phone: res.data.individual.cellphone,
          //@ts-ignore
          gender: res.data.individual.custom_fields.find(el => el === "Sesso")
            ? //@ts-ignore
              res.data.individual.custom_fields.find(el => el === "Sesso").value
            : "",
          loyalty: {
            balance: res.data.q_point,
            code: res.data.card_code,
            tier: res.data.tier.name,
          },
          country: "IT",
          lang: "it",
        }; */

        const rcpts = [
          {
            email: res.data.individual.email,
            firstname: res.data.individual.firstname,
            c0: e.key,
            //@ts-ignore
            c5: res.data.individual.custom_fields.find(el => el.name === "CAP")
              ? //@ts-ignore
                res.data.individual.custom_fields.find(el => el.name === "CAP").value
              : "",
            //@ts-ignore
            c44: res.data.individual.custom_fields.find(el => el.name === "Sesso")
              ? //@ts-ignore
                res.data.individual.custom_fields.find(el => el.name === "Sesso").value
              : "",
            //@ts-ignore
            c12: moment(e.birthday).format("YYYY-MM-DD"),
          },
        ];

        const params = {
          universe: API_PARAMS.TRIGGER_UNIVERSE,
          key: API_PARAMS.TRIGGER_API_KEY,
          message: API_PARAMS.MESSAGE_ID,
          rcpts: JSON.stringify(rcpts),
        };

        // TRIGGER CALL
        triggerClient
          .post("", qs.stringify(params), {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          })
          .then(response => {
            countDownModal();
            // TRIGGER CALL
          })
          .catch(error => {
            countDownModal();
          });
      });
  };

  useEffect(() => {
    apiClient.post(API_ENDPOINTS.GET_TOKEN, { api_key: API_PARAMS.API_KEY }).then(res => {
      //@ts-ignore;
      if (res.data) {
        //@ts-ignore;
        utilitySaveToLocalStorage("token", res.data.token);
      }

      apiClient
        .get(API_ENDPOINTS.GET_PROGRAMS, {
          headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
        })
        .then(res => {
          setProgramList(res.data.elements);
        });
    });
  }, []);
  return (
    <>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Form
            style={{ justifyContent: "center" }}
            layout="vertical"
            name="basic"
            initialValues={{ key: key }}
            onFinish={handleAssign}
            onFinishFailed={(e): void => console.log(e)}
          >
            <Row justify="center" style={{ display: "none" }}>
              <Col md={8}>
                <Form.Item
                  label="Codice Utente"
                  name="key"
                  rules={[{ required: true, message: "Codice necessario" }]}
                >
                  <UIInput />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={12} md={8}>
                <Form.Item
                  name="birthday"
                  rules={[{ required: true, message: "Immettere una data valida" }]}
                  label="Data di nascita"
                >
                  <UIDatePicker format={"DD/MM/YYYY"} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={12} md={8}>
                <Form.Item
                  name="program_id"
                  label="Programma"
                  rules={[{ required: true, message: "Seleziona un programma!" }]}
                  extra="Il programma da associare"
                >
                  <UISelect>
                    {programList?.map(
                      (program): JSX.Element => (
                        //@ts-ignore
                        <Option key={program.id} value={program.id}>
                          {
                            //@ts-ignore
                            program.name
                          }
                        </Option>
                      ),
                    )}
                  </UISelect>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <UIButton type="primary" htmlType="submit">
                Assegna Loyalty
              </UIButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
