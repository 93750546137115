/** @format */

import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import styled from "styled-components";

interface Props extends CardProps {}

export const UICard = styled(Card)<Props>`
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
`;
