/** @format */

import { Row, Col, Form } from "antd";
import moment from "moment";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { API_ENDPOINTS, API_PARAMS } from "../../../constants/api";
import { Colors } from "../../../theme/maps/Colors";
import apiClient, { triggerClient } from "../../../utils/apiClient";
import { utilityGetFromLocalStorage } from "../../../utils/cache/getFromLocalStorage";
import { utilityGenerateRandomString } from "../../../utils/generateRandomString";
import { utilitySaveToLocalStorage } from "../../../utils/cache/saveToLocalStorage";
import { Routes } from "../../../utils/Routes";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UICheckbox } from "../../ui/Checkbox";
import { UIDatePicker } from "../../ui/DatePicker";
import { UIInput } from "../../ui/Input";
import { UISelect } from "../../ui/Select";
import { UITitle } from "../../ui/Title";
const { Option } = UISelect;

export const ViewUserCreate = (): JSX.Element => {
  const history = useHistory();
  const [programList, setProgramList] = useState<[]>([]);

  /*   const countDownModal = (title?: string, text?: string): void => {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: title || "Operazione Completata",
      content: text || `Presto riceverai un'email al tuo indirizzo di posta elettronica!`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  };

  const countDownErrorModal = (title?: string, text?: string): void => {
    let secondsToGo = 5;
    const modal = Modal.warning({
      title: title || "Operazione Completata",
      content: text || `Presto riceverai un'email al tuo indirizzo di posta elettronica!`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }; */

  //@ts-ignore
  const handleSave = (e): void => {
    const key = utilityGenerateRandomString("LL");
    const userObj = {
      firstname: e.firstName,
      lastname: e.lastName,
      email: e.email,
      cellphone: e.cellphone,
      creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      custom_fields: [
        { id: 0, name: "Chiave", value: key },
        { id: 13, name: "Professione", value: e.job },
        { id: 5, name: "CAP", value: e.cap },
        { id: 44, name: "Sesso", value: e.gender },
        { id: 48, name: "FlagPrivacy", value: "1" },
      ],
    };
    //dispatch

    if (!!e.emailCheck) {
      userObj.custom_fields.push({ id: 32, name: "ConsensoEmail", value: "1" });
      userObj.custom_fields.push({
        id: 16,
        name: "DataIscrizioneEmail",
        value: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }

    console.log(e.emailCheck);
    //STEP 1: Verifica l'esistenza dell'email
    apiClient
      .get(API_ENDPOINTS.SEARCH_CONTACT + e.email, {
        headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
      })
      .then(res => {
        if (!!res.data.elements.length) {
          // TROVA IL CONTATTO
          if (res.data.elements[0].cellphone !== "")
            userObj.cellphone = res.data.elements[0].cellphone;
          if (res.data.elements[0].firstname !== "")
            userObj.firstname = res.data.elements[0].firstname;
          if (res.data.elements[0].lastname !== "")
            userObj.lastname = res.data.elements[0].lastname;
          //@ts-ignore
          if (res.data.elements[0].custom_fields.find(el => el.name === "Professione").value !== "")
            //@ts-ignore
            userObj.custom_fields.find(
              //@ts-ignore
              el => el.name === "Professione",
            ).value = res.data.elements[0].custom_fields.find(
              //@ts-ignore
              el => el.name === "Professione",
            ).value;

          //@ts-ignore
          if (res.data.elements[0].custom_fields.find(el => el.name === "CAP").value !== "")
            //@ts-ignore
            userObj.custom_fields.find(
              //@ts-ignore
              el => el.name === "CAP",
              //@ts-ignore
            ).value = res.data.elements[0].custom_fields.find(el => el.name === "CAP").value;

          //@ts-ignore
          if (res.data.elements[0].custom_fields.find(el => el.name === "Sesso").value !== "")
            //@ts-ignore
            userObj.custom_fields.find(
              //@ts-ignore
              el => el.name === "Sesso",
              //@ts-ignore
            ).value = res.data.elements[0].custom_fields.find(el => el.name === "Sesso").value;

          const newUserObj = {
            firstname: userObj.firstname,
            lastname: userObj.lastname,
            email: userObj.email,
            cellphone: userObj.cellphone,
            custom_fields: userObj.custom_fields.filter(el => el.name !== "Chiave"),
          };

          //ISCRIZIONE ALLA NEWSLETTER
          const lists = !!e.emailCheck ? [0, 1, 11] : [0];

          apiClient.post(
            API_ENDPOINTS.SUBSCRIBE_TO_LIST.replace(":id", key),
            {
              list_ids: lists,
            },
            {
              headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
            },
          );

          if (!!!res.data.elements[0].loyalty.length) {
            // CONTATTO SENZA LOYAL
            apiClient
              .patch(
                API_ENDPOINTS.CREATE_CONTACT +
                  "/" +
                  //@ts-ignore
                  res.data.elements[0].custom_fields.find(el => el.name === "Chiave").value,
                newUserObj,
                {
                  headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
                },
              )
              .then(res => {
                //@ts-ignore
                const key = res.data.elements[0].custom_fields.find(el => el.name === "Chiave")
                  .value;

                const loyaltyObj = {
                  program_id: e.program_id,
                  //@ts-ignore
                  contact_id: key,
                  birthday: moment(e.birthday).format("YYYY-MM-DD"),
                };

                apiClient
                  .post(API_ENDPOINTS.CREATE_LOYALTY, loyaltyObj, {
                    headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
                  })
                  .then(res => {
                    const rcpts = [
                      {
                        email: e.email,
                        firstname: e.firstName,
                        c0: key,
                        //@ts-ignore
                        c5: res.data.individual.custom_fields.find(el => el.name === "CAP")
                          ? //@ts-ignore
                            res.data.individual.custom_fields.find(el => el.name === "CAP").value
                          : "",
                        //@ts-ignore
                        c44: res.data.individual.custom_fields.find(el => el.name === "Sesso")
                          ? //@ts-ignore
                            res.data.individual.custom_fields.find(el => el.name === "Sesso").value
                          : "",
                        //@ts-ignore
                        c12: moment(e.birthday).format("YYYY-MM-DD"),
                      },
                    ];

                    const params = {
                      universe: API_PARAMS.TRIGGER_UNIVERSE,
                      key: API_PARAMS.TRIGGER_API_KEY,
                      message: API_PARAMS.MESSAGE_ID,
                      rcpts: JSON.stringify(rcpts),
                    };

                    // TRIGGER CALL
                    triggerClient
                      .post("", qs.stringify(params), {
                        headers: { "content-type": "application/x-www-form-urlencoded" },
                      })
                      .then(response => {
                        //countDownModal();
                        const aesObj = {
                          externalId: key,
                          firstName: e.firstName,
                          lastName: e.lastName,
                          email: e.email,
                          postal: e.cap,
                          gender: e.gender,
                        };
                        history.push(
                          Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))),
                        ); // TRIGGER CALL
                      })
                      .catch(error => {
                        //countDownModal();
                        const aesObj = {
                          externalId: key,
                          firstName: e.firstName,
                          lastName: e.lastName,
                          email: e.email,
                          postal: e.cap,
                          gender: e.gender,
                        };
                        history.push(
                          Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))),
                        );
                      });
                  });
              });
          } else {
            const rcpts = [
              {
                email: e.email,
                firstname: res.data.elements[0].firstname,
                c0: key,
                //@ts-ignore
                c5: res.data.elements[0].custom_fields.find(el => el.name === "CAP")
                  ? //@ts-ignore
                    res.data.elements[0].custom_fields.find(el => el.name === "CAP").value
                  : "",
                //@ts-ignore
                c44: res.data.elements[0].custom_fields.find(el => el.name === "Sesso")
                  ? //@ts-ignore
                    res.data.elements[0].custom_fields.find(el => el.name === "Sesso").value
                  : "",
                //@ts-ignore
                c12: moment(e.birthday).format("YYYY-MM-DD"),
              },
            ];

            const params = {
              universe: API_PARAMS.TRIGGER_UNIVERSE,
              key: API_PARAMS.TRIGGER_API_KEY,
              message: API_PARAMS.MESSAGE_ID,
              rcpts: JSON.stringify(rcpts),
            };

            // TRIGGER CALL
            triggerClient
              .post("", qs.stringify(params), {
                headers: { "content-type": "application/x-www-form-urlencoded" },
              })
              .then(response => {
                //countDownModal();
                // TRIGGER CALL

                const aesObj = { error: true };
                history.push(Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))));
              })
              .catch(error => {
                //countDownModal();
                const aesObj = { error: true };
                history.push(Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))));
              });
          }
        } else {
          // CREA CONTATTO
          apiClient
            .post(API_ENDPOINTS.CREATE_CONTACT, userObj, {
              headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
            })
            .then(result => {
              //@ts-ignore
              const key = result.data.custom_fields.find(el => el.name === "Chiave").value;

              const loyaltyObj = {
                program_id: e.program_id,
                //@ts-ignore
                contact_id: key,
                birthday: moment(e.birthday).format("YYYY-MM-DD"),
              };

              //ISCRIZIONE ALLA NEWSLETTER
              const lists = !!e.emailCheck ? [0, 1, 11] : [0];

              apiClient.post(
                API_ENDPOINTS.SUBSCRIBE_TO_LIST.replace(":id", key),
                {
                  list_ids: lists,
                },
                {
                  headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
                },
              );

              apiClient
                .post(API_ENDPOINTS.CREATE_LOYALTY, loyaltyObj, {
                  headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
                })
                .then(res => {
                  const rcpts = [
                    {
                      email: e.email,
                      firstname: e.firstName,
                      c0: key,
                      //@ts-ignore
                      c5: res.data.individual.custom_fields.find(el => el.name === "CAP")
                        ? //@ts-ignore
                          res.data.individual.custom_fields.find(el => el.name === "CAP").value
                        : "",
                      //@ts-ignore
                      c44: res.data.individual.custom_fields.find(el => el.name === "Sesso")
                        ? //@ts-ignore
                          res.data.individual.custom_fields.find(el => el.name === "Sesso").value
                        : "",
                      //@ts-ignore
                      c12: moment(e.birthday).format("YYYY-MM-DD"),
                    },
                  ];

                  const params = {
                    universe: API_PARAMS.TRIGGER_UNIVERSE,
                    key: API_PARAMS.TRIGGER_API_KEY,
                    message: API_PARAMS.MESSAGE_ID,
                    rcpts: JSON.stringify(rcpts),
                  };

                  // TRIGGER CALL
                  triggerClient
                    .post("", qs.stringify(params), {
                      headers: { "content-type": "application/x-www-form-urlencoded" },
                    })
                    .then(response => {
                      //countDownModal();
                      // TRIGGER CALL

                      const aesObj = {
                        externalId: key,
                        firstName: e.firstName,
                        lastName: e.lastName,
                        email: e.email,
                        postal: e.cap,
                        gender: e.gender,
                      };
                      history.push(
                        Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))),
                      );
                    })
                    .catch(error => {
                      //countDownModal();
                      const aesObj = {
                        externalId: key,
                        firstName: e.firstName,
                        lastName: e.lastName,
                        email: e.email,
                        postal: e.cap,
                        gender: e.gender,
                      };
                      history.push(
                        Routes.viewButton.replace(":urlBase", btoa(JSON.stringify(aesObj))),
                      );
                    });
                });
            });
        }
      });
  };

  useEffect(() => {
    moment.locale("it");

    apiClient.post(API_ENDPOINTS.GET_TOKEN, { api_key: API_PARAMS.API_KEY }).then(res => {
      //@ts-ignore;
      if (res.data) {
        //@ts-ignore;
        utilitySaveToLocalStorage("token", res.data.token);
      }

      apiClient
        .get(API_ENDPOINTS.GET_PROGRAMS, {
          headers: { Authorization: `Bearer ${utilityGetFromLocalStorage("token")}` },
        })
        .then(res => {
          setProgramList(res.data.elements);
        });
    });
  }, []);

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col style={{ marginTop: "15px" }}>
            <UITitle level={2} color={Colors.darkGray}>
              Registrazione Loyalty
            </UITitle>
          </Col>
        </Row>
        <Form
          name="new-user"
          className="new-user"
          onFinish={handleSave}
          initialValues={{ program_id: 1 }}
          layout="vertical"
        >
          <Row justify="space-between" gutter={[16, 16]}>
            <Col xs={24}>
              <UICard>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="firstName"
                      label="Nome"
                      rules={[{ required: true, message: "Inserisci un nome valido" }]}
                      extra="Il nome dell'utente"
                    >
                      <UIInput />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="lastName"
                      label="Cognome"
                      rules={[{ required: true, message: "Inserisci un cognome valido" }]}
                      extra="Il cognome dell'utente"
                    >
                      <UIInput />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="birthday"
                      rules={[{ required: true, message: "Immettere una data valida." }]}
                      label="Data di nascita"
                    >
                      <UIDatePicker format={"DD/MM/YYYY"} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Form.Item
                      name="gender"
                      label="Sesso"
                      rules={[{ required: false, message: "Seleziona un genere" }]}
                    >
                      <UISelect>
                        <Option value="M">M</Option>
                        <Option value="F">F</Option>
                      </UISelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      style={{ display: "none" }}
                      name="program_id"
                      label="Programma"
                      rules={[{ required: true, message: "Seleziona un programma!" }]}
                      extra="Il programma da associare"
                    >
                      <UISelect>
                        {programList?.map(
                          (program): JSX.Element => (
                            //@ts-ignore
                            <Option key={program.id} value={program.id}>
                              {
                                //@ts-ignore
                                program.name
                              }
                            </Option>
                          ),
                        )}
                      </UISelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={10}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, type: "email", message: "Inserisci un'email valida." },
                      ]}
                      extra="L'indirizzo di posta elettronica dell'utente"
                    >
                      <UIInput type="email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={10}>
                    <Form.Item
                      name="cellphone"
                      label="N° Mobile"
                      rules={[
                        {
                          required: false,
                          pattern: new RegExp("^([0|+[0-9]{1,5})?([0-9]{10})$"),
                          message: "Inserisci un numero di cellulare valido.",
                        },
                      ]}
                      extra="Il numero di cellulare dell'utente"
                    >
                      <UIInput addonBefore={selectBefore} type="tel" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} md={4}>
                    <Form.Item
                      name="cap"
                      label="CAP"
                      rules={[
                        {
                          required: true,
                          len: 5,
                          message: "Inserisci un codice CAP valido",
                        },
                      ]}
                      extra="Il CAP dell'utente"
                    >
                      <UIInput type="number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="job"
                      label="Professione"
                      rules={[{ required: false, message: "Seleziona una professione!" }]}
                      extra="La professione dell'utente"
                    >
                      <UISelect>
                        <Option value="Architetto">Architetto</Option>
                        <Option value="Docente">Docente</Option>
                        <Option value="Fotografo">Fotografo</Option>
                        <Option value="Grafico">Grafico</Option>
                        <Option value="Ristoratore">Ristoratore</Option>
                        <Option value="Studente">Studente</Option>
                        <Option value="Altro">Altro</Option>
                      </UISelect>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="emailCheck"
                      valuePropName="checked"
                      label="Iscrizione alla Newsletter"
                      rules={[{ required: false, message: "!" }]}
                    >
                      <UICheckbox value={1} />
                    </Form.Item>
                  </Col>{" "}
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="privacy"
                      valuePropName="checked"
                      label="Accetto la Privacy Policy"
                      rules={[
                        {
                          required: true,
                          validator: (_, value): Promise<unknown> =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("E' necessaria l'accettazione dell'informativa."),
                                ),
                        },
                      ]}
                    >
                      <UICheckbox />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end" style={{ marginTop: "15px" }} gutter={[24, 24]}>
                  <Col>
                    <NavLink to={Routes.home}>
                      <UIButton
                        bgColor={Colors.darkGray}
                        hoverBgColor={Colors.darkGray}
                        borderColor={Colors.darkGray}
                        color={Colors.primary}
                      >
                        Annulla
                      </UIButton>
                    </NavLink>
                  </Col>
                  <Col>
                    <Form.Item>
                      <UIButton
                        bgColor={Colors.blue}
                        hoverBgColor={Colors.blue}
                        borderColor={Colors.blue}
                        color={Colors.primary}
                        htmlType="submit"
                      >
                        Continua
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </UICard>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const selectBefore = (
  //@ts-ignore
  <UISelect defaultValue="+39" className="select-before">
    <Option value="+39">+39</Option>
    <Option value="+43">+43</Option>
    <Option value="+32">+32</Option>
    <Option value="+45">+45</Option>
    <Option value="+33">+33</Option>
    <Option value="+49">+49</Option>
    <Option value="+30">+30</Option>
    <Option value="+353">+353</Option>
    <Option value="+354">+354</Option>
    <Option value="+371">+371</Option>
    <Option value="+352">+352</Option>
    <Option value="+370">+370</Option>
    <Option value="+356">+356</Option>
    <Option value="+377">+377</Option>
    <Option value="+47">+47</Option>
    <Option value="+31">+31</Option>
    <Option value="+48">+48</Option>
    <Option value="+351">+351</Option>
    <Option value="+44">+44</Option>
    <Option value="+420">+420</Option>
    <Option value="+40">+40</Option>
    <Option value="+7">+7</Option>
    <Option value="+378">+378</Option>
    <Option value="+379">+379</Option>
    <Option value="+386">+386</Option>
    <Option value="+34">+34</Option>
    <Option value="+46">+46</Option>
    <Option value="+41">+41</Option>
    <Option value="+380">+380</Option>
    <Option value="+36">+36</Option>
  </UISelect>
);
