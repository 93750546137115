/** @format */

import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import styled from "styled-components";

interface Props extends SelectProps<"inputIcon"> {}

export const UISelect = styled(Select)<Props>`
  border-radius: 10px;

  .ant-select-selector {
    border-radius: 10px !important;
  }
`;
