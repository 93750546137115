/** @format */

import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";

interface Props extends ButtonProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
  fullWidth?: boolean;
  hoverBgColor?: string;
}

export const UIButton = styled(Button)<Props>`
  border-radius: 20px;
  ${(props): string => (props.fullWidth ? "width: 100%;" : "")}
  ${(props): string => (props.color ? "color: " + props.color + ";" : "")}
  ${(props): string => (props.bgColor ? "background-color: " + props.bgColor + ";" : "")}
  ${(props): string => (props.borderColor ? "border-color: " + props.borderColor + ";" : "")}

  &:hover {
    ${(props): string => (props.color ? "color: " + props.color + ";" : "")}
    ${(props): string =>
      props.hoverBgColor ? "background-color: " + props.hoverBgColor + ";" : ""}
  }
`;
