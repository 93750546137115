/** @format */

export const API_PARAMS = {
  BASE_URL: process.env.REACT_APP_API_URL,
  BASE_URL_AES: "https://aes.icnetwork.it",
  TRIGGER_URL: "https://s3s.fr/api/trigger/nph-9.pl",
  MESSAGE_BASE_URL: "https://admin.gowento.com/rstore-sandbox/urls/6007021e531701015466b254",
  MESSAGE_CUSTOM_FIELD_CODE: "c43",
  MESSAGE_ID: "7NnWybtnS",
  TRIGGER_API_KEY: "ed97c71f410a5b16222d5a8531f9a23b91cccce3",
  TRIGGER_UNIVERSE: "rstore_test",
  API_KEY: process.env.REACT_APP_API_KEY,
};

export const API_ENDPOINTS = {
  SEARCH_CONTACT:
    "/data/contacts?per_page=20&page_number=1&fields[0][key]=email&fields[0][operator]=is&fields[0][value]=",
  GET_TOKEN: "/authenticate",
  GET_PROGRAMS: "/loyalty/programs",
  GET_LISTS: "/data/v1/lists",
  CREATE_CONTACT: "/data/contacts",
  SUBSCRIBE_TO_LIST: "/data/contacts/:id/lists/subscribe",
  CREATE_LOYALTY: "/loyalty/members",
};
