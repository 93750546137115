/** @format */

import React, { useEffect } from "react";
import logo from "./assets/logo/logo-rstore.png";
import "./App.less";
import { Col, Layout, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import { NavLink, Route, Switch } from "react-router-dom";
import { Routes } from "./utils/Routes";
import { ViewUserCreate } from "./components/views/ViewUserCreate";
import { ViewUserDetail } from "./components/views/ViewUserDetails";
import apiClient from "./utils/apiClient";
import { API_ENDPOINTS, API_PARAMS } from "./constants/api";
import { utilityRemoveLocalStorage } from "./utils/cache/removeFromLocalStorage";
import { utilitySaveToLocalStorage } from "./utils/cache/saveToLocalStorage";
import { utilityGetFromLocalStorage } from "./utils/cache/getFromLocalStorage";
import { ViewAssignLoyalty } from "./components/views/ViewAssignLoyalty";
import { ViewButton } from "./components/views/ViewButton";
/* import { ViewHomeUser } from "./components/views/viewHomeUser";
 */
function App(): JSX.Element {
  const updateApiToken = (): void => {
    if (utilityGetFromLocalStorage("token").length > 2) {
      utilityRemoveLocalStorage("token");
    }

    apiClient.post(API_ENDPOINTS.GET_TOKEN, { api_key: API_PARAMS.API_KEY }).then(res => {
      //@ts-ignore;
      if (res.data) {
        //@ts-ignore;
        utilitySaveToLocalStorage("token", res.data.token);
      }
    });

    setInterval(updateApiToken, 1000 * 60 * 60);
  };
  useEffect(() => {
    updateApiToken();
  }, []);
  return (
    <>
      <Layout>
        <Header className="header" style={{ height: "72px" }}>
          <Row>
            <Col span={12}>
              <NavLink to={Routes.home}>
                <img src={logo} />
              </NavLink>
            </Col>
          </Row>
        </Header>
      </Layout>
      <>
        <Switch>
          <Route path={Routes.home} exact component={ViewUserCreate} />
          <Route path={Routes.userCreate} exact component={ViewUserCreate} />
          <Route path={Routes.userDetail} exact component={ViewUserDetail} />
          <Route path={Routes.assignLoyalty} exact component={ViewAssignLoyalty} />
          <Route path={Routes.viewButton} exact component={ViewButton} />
        </Switch>
      </>
    </>
  );
}

export default App;
